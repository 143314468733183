import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {useRouter} from "next/router";
import dynamic from 'next/dynamic'
import {IState} from "../../../types/state";
import {ILocales} from "./types";

// @ts-ignore
const Dropdown = dynamic(() => import('./Dropdown'))

function DropdownLanguage() {
    const router = useRouter();
    const locale = useSelector((state: IState) => state.locale);
    const languages = useSelector((state: IState) => state.locale.list);
    const [newLocal, setNewLocal] = useState(router.locale === 'catchAll' ? locale.code : router.locale)
    useEffect(() => {
    }, [newLocal])
    if (!languages) {
        return null;
    }
    const handleRoute = (locale: any) => {
        setNewLocal(() => locale)
        return router.push(
            router.asPath !== "/" ? router.asPath : "",
            router.asPath !== "/" ? router.asPath : "",
            {locale: locale.code}
        );
    };

    const title = <FormattedMessage id="topBar.language" defaultMessage="English"/>


    return (
        <Dropdown
            withIcons
            forWhat="language"
            title={title}
            locale={locale as ILocales}
            newLocal={locale.code}
            items={languages}
            onClick={handleRoute}
        />
    );
}


export default DropdownLanguage;